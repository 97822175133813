{
  "name": "@devfu/anthem",
  "version": "2024.2.2",
  "license": "Proprietary",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 9200",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "jest",
    "test:watch": "jest --watch",
    "test:coverage": "jest --coverage",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build:prod": "ng build --target production --build-optimizer --vendor-chunk --common-chunk",
    "clean": "rm -rf ./dist/",
    "server": "cd dist && http-server",
    "prod": "npm run clean && npm run build:prod && npm run server",
    "ci": "npm run clean && npm run prettier:ci && ng lint && ng test --browser ChromeTravisCi --single-run --reporters spec --environment prod && ng e2e && npm run build:prod -- --deploy-url /angular-ngrx-material-starter/ --base-href /angular-ngrx-material-starter",
    "prettier": "prettier {src,e2e}/**/*.{ts,json,md,scss} --write",
    "prettier:ci": "prettier {src,e2e}/**/*.{ts,json,md,scss} --list-different",
    "analyze": "npm run clean && npm run build:prod -- --stats-json && webpack-bundle-analyzer ./dist/stats.json",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.3",
    "@angular/cdk": "18.2.3",
    "@angular/common": "18.2.3",
    "@angular/compiler": "18.2.3",
    "@angular/core": "18.2.3",
    "@angular/forms": "18.2.3",
    "@angular/material": "18.2.3",
    "@angular/material-moment-adapter": "18.2.3",
    "@angular/platform-browser": "18.2.3",
    "@angular/platform-browser-dynamic": "18.2.3",
    "@angular/router": "18.2.3",
    "@fortawesome/fontawesome-free": "6.1.0",
    "@popperjs/core": "^2.11.7",
    "ajv-keywords": "^5.1.0",
    "angular-screenfull": "1.0.0",
    "bootstrap": "^5.2.3",
    "core-js": "~3.36.0",
    "cypress": "^13.7.0",
    "jwt-decode": "^3.1.2",
    "material-icons": "^1.13.12",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.43",
    "ng-in-viewport": "^13.0.1",
    "ngx-bootstrap": "^18.0.2",
    "ngx-device-detector": "^8.0.0",
    "rxjs": "~7.8.0",
    "sass": "^1.62.0",
    "tslib": "^2.0.0",
    "typescript": "^5.5.4",
    "web-animations-js": "^2.3.2",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/core": "18.2.3",
    "@angular-eslint/eslint-plugin": "^18.3.0",
    "@angular/build": "^18.2.3",
    "@angular/cli": "18.2.3",
    "@angular/compiler-cli": "18.2.3",
    "@angular/localize": "18.2.3",
    "@cypress/schematic": "^2.5.1",
    "@types/jest": "^29.5.12",
    "@types/node": "^18.15.11",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "ajv": "~8.10.0",
    "cypress": "^13.7.0",
    "enhanced-resolve": "~5.9.2",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.29.1",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.2.2",
    "prettier": "~2.6.0",
    "ts-node": "~10.7.0",
    "webpack-bundle-analyzer": "~4.5.0"
  },
  "optionalDependencies": {
    "@rollup/rollup-linux-x64-musl": "4.21.2",
    "@lmdb/lmdb-linux-x64": "3.1.0"
  },
  "jest": {
    "preset": "jest-preset-angular",
    "setupFilesAfterEnv": [
      "<rootDir>/src/setup.jest.ts"
    ],
    "testPathIgnorePatterns": [
      "<rootDir>/node_modules/",
      "<rootDir>/dist/"
    ],
    "globals": {
      "ts-jest": {
        "tsConfig": "<rootDir>/tsconfig.spec.json",
        "stringifyContentPathRegex": "\\.html$"
      }
    },
    "moduleNameMapper": {
      "@app/(.*)": "<rootDir>/src/app/$1",
      "@env/(.*)": "<rootDir>/src/environments/$1"
    }
  }
}
